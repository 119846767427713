<template>
  <div>
    <Header />

    <div class="content">
      <router-view :key="key" />
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  min-height: calc(100vh - 87px - 166px);
}
</style>
