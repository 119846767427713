<template>
  <div class="footer">
    <div class="content w">
      <div class="logo">
        <img src="@/assets/logo2.png" alt="" />
      </div>
      <div class="left">
        <p>热线电话：4006196928</p>
        <p>邮箱：kaka@renhe.love</p>
        <p>地址：上海市徐汇区田林坊130号20号楼</p>
      </div>
      <div class="right">
        <p>经营许可编号：沪B2-20210356</p>
        <p>互联网药品信息服务资格证书：(沪)-经营性-2021-0031</p>
        <p>上海妙回信息科技有限公司 版权所有 沪ICP备20019131号-1</p>
        <p>沪公网安备 31010402009244号</p>
      </div>
      <img src="@/assets/erweima.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style  lang="less"scoped>
* {
  padding: 0;
  margin: 0;
}
.w {
  width: 1200px;
  margin: auto;
}
.footer {
  background: #282828;
  height: 166px;
  width: 100%;
  .content {
    display: flex;
    position: relative;
  }
  .content img {
    position: absolute;
    top: 36px;
    right: 0;
  }
  .content .logo {
    width: 83px;
    height: 23px;
  }
  .content .logo img {
    position: absolute;
    top: 24px;
    left: 0;
  }

  .content .left {
    width: 252px;
    height: 72px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgb(123, 123, 123);
    line-height: 24px;

    position: absolute;
    top: 71px;
  }

  .content .right {
    position: absolute;
    top: 47px;
    left: 35%;

    width: 395px;
    height: 96px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgb(123, 123, 123);
    line-height: 24px;
  }
  .content .right p:last-child {
    text-indent: 24px;
  }
  .content .right p:last-child::after {
    content: "";
    background: url(@/assets/beiantubiao.png) no-repeat -1px;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0;
  }
}
</style>
