<template>
  <div id="app">
    <!-- <Header> </Header>
    <Banner></Banner>
    <Nav></Nav>
    <Hot></Hot>
    <Footer></Footer> -->
    <router-view />
  </div>
</template>

<script>
// import Header from "./components/Header/Header.vue";
// import Banner from "./components/Banner/Banner.vue";
// import Footer from "./components/Footer/Footer.vue";
// import Nav from "./components/Nav/Nav.vue";
// import Hot from "./components/Hot/Hot.vue";
export default {
  name: "App",
  components: {
    // Header,
    // Banner,
    // Footer,
    // Nav,
    // Hot,
  },
};
</script>

<style lang="less">
body {
  margin: 0;
}
</style>
