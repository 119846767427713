<template>
  <div class="header w">
    <!-- logo部分 -->
    <div class="logo">
      <img src="@/assets/logo1.png" alt="" />
      <span>妙回信息</span>
    </div>
    <!--  搜索框 -->
    <div class="search">
      <input type="text" placeholder="搜索" />
    </div>
    <!-- 导航栏 -->
    <div class="nav-list">
      <ul>
        <li @click="$router.push('/home')">首页</li>
        <li @click="$router.push('/tonic')">滋补调养</li>
        <li @click="$router.push('/heat')">清热解毒</li>
        <li @click="$router.push('/baby')">小儿用药</li>
      </ul>
    </div>
    <!-- 登录注册 -->
    <div class="login">
      <button @click="handleLogin">登录</button>
      <button>注册</button>
    </div>

    <div class="bounced" v-if="isDialog" @click.prevent="close">
      <div class="bounced-content" @click.stop="isDialog = true">
        <div class="title">登录</div>

        <div class="user-content">
          <div class="user-item">
            <div class="label">用户名</div>
            <div class="input-content">
              <input type="text" placeholder="请输入" />
              <img src="@/assets/user.png" alt="" />
            </div>
          </div>
          <div class="user-item">
            <dir class="label">密码</dir>
            <div class="input-content">
              <input type="text" placeholder="请输入" />
              <img src="@/assets/password.png" alt="" />
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="box"></div>
          记住密码
        </div>

        <div class="btn">立即登录</div>

        <img
          class="closeImg"
          src="@/assets/close.png"
          alt=""
          @click.stop="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isDialog: false,
    };
  },
  methods: {
    handleLogin() {
      this.isDialog = true;
    },
    close(e) {
      e.stopPropagation();
      e.preventDefault();

      this.isDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
}
.w {
  width: 1200px;
  margin: auto;
}
.header {
  position: relative;
  display: flex;
  height: 37px;
  background-color: #ffffff;
  margin: 25px 20%;
  .logo {
    position: relative;
    width: 142px;
    height: 37px;
    background-color: #ffffff;
    img {
      top: 3px;
      position: absolute;
      width: 28px;
      height: 32px;
    }
    span {
      font-size: 26px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #eb662d;
      line-height: 37px;
      padding-left: 34px;
    }
  }
  .search {
    margin-left: 32px;
    width: 132px;
  }
  .search input {
    width: 132px;
    height: 34px;
    background: #ffffff;
    border-radius: 17px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    outline: none;
    padding-left: 38px;
  }

  .nav-list {
    margin-left: 163px;
    ul {
      display: flex;
    }
    li {
      list-style: none;
      display: block;
      height: 61px;
      line-height: 37px;
      padding: 0 20px;
      text-decoration: none;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
    }
  }
  .nav-list ul li:hover,
  .nav-list ul li:active,
  .nav-list ul li:focus {
    border-bottom: 1px solid #eb662d;
  }
  .nav-list ul li:nth-child(1 + n) {
    margin-left: 62px;
  }
  .login {
    position: absolute;
    right: 0;

    button:nth-child(1) {
      width: 96px;
      height: 34px;
      background: #eb662d;
      border-radius: 18px;
      color: #ffffff;
      border: 1px solid #eb662d;
    }
    button:nth-child(2) {
      width: 96px;
      height: 34px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #eb662d;
      margin-left: 22px;
      color: #eb662d;
    }
  }
}

.bounced {
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  .bounced-content {
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    padding: 59px 84px 79px 84px;
    .title {
      font-size: 34px;
      text-align: center;
    }
    .user-content {
      padding-top: 36px;
      .user-item {
        margin-bottom: 24px;
        .label {
          font-size: 14px;
          color: rgba(#000, 0.4);
        }
        .input-content {
          position: relative;

          > input {
            width: 415px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #eb662d;
            padding-left: 30px;

            &::placeholder {
              color: rgba(#000, 0.25);
            }
          }
          > img {
            width: 15px;
            height: 18px;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .closeImg {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 22px;
      height: 22px;
    }
    .footer {
      display: flex;
      color: rgba(#000, 0.65);
      font-size: 14px;
      .box {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        margin-right: 10px;
      }
    }
    .btn {
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      margin-top: 36px;
      background-color: #eb662d;
    }
  }
}
</style>
