import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Layout from '@/layout'

const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'Home',
      component: () => import('@/views/home/index'),
    },
    {
      path: 'home/details',
      name: 'HomeDetails',
      component: () => import('@/views/home/details'),
    },
    {
      path: 'tonic',
      name: 'Tonic',
      component: () => import('@/views/tonic/index')
    },
    {
      path: 'tonic/details',
      name: 'TonicDetails',
      component: () => import('@/views/tonic/details'),
    },
    {
      path: 'heat',
      name: 'Heat',
      component: () => import('@/views/heat/index')
    },
    {
      path: 'heat/details',
      name: 'HeatDetails',
      component: () => import('@/views/heat/details'),
    },
    {
      path: 'baby',
      name: 'Baby',
      component: () => import('@/views/baby/index')
    },
    {
      path: 'baby/details',
      name: 'BabyDetails',
      component: () => import('@/views/baby/details'),
    },
    ]
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router